import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from 'components/layout'
import PageHeader from 'components/page-header'

const IndustryLeadershipPage = ({ data }) => (
  <Layout>
    <PageHeader 
      title="Industry Leadership" 
      sizes={data.headerImage.childImageSharp.fluid} 
    />
      
    <div className="l-primary">
      <nav>
        <h3>Industry &amp; Community</h3>
        <ul>
          <li><Link to={'/industry-and-community/industry-leadership'}>Industry Leadership</Link></li>
          <li><Link to={'/industry-and-community/community-leadership'}>Community Leadership</Link></li>
        </ul> 
      </nav>
      <section>
        <p>At Howell Farms we consider it a privilege and our duty to set aside time and put forth the effort to help promote the agriculture industry.   We do this for two major reasons.  Farms and farmers are few in number.  See our <Link to={'/fundamentals/family-farming'}>family farming</Link> page to understand just how few we are.  Each succeeding generation of the non-farm public is further removed from any contact or even knowledge of agriculture.  We believe it is important to maintain a continuing dialogue with our non-farm neighbors to improve understanding of our industry.  The second reason is to promote what we produce.</p>
        <p>The industry promotion we do takes many forms.  We provide tours for elementary school children seeking to educate them in the source of their food and importance of agriculture in their daily lives.  Katy and Keri Howell create educational displays at the Delaware County Ag Days where they illustrate and hand out crop production visuals to the attendees.  We communicate regularly with our elected officials on issues relating to agriculture.  David has given Congressional testimony multiple times and is on our congressman’s agriculture advisory committee. He has a long tenure of leadership roles in national agriculture organizations.  He currently serves in an advisory capacity to the Purdue Center for Commercial Agriculture and Farm Policy Committee as well as the Indiana Grain Indemnity Corporation.  Aaron completed the two year Indiana Ag Leadership Program which aims to prepare participants for leadership roles in Indiana agriculture.  Adam is a board member of the Indiana Corn Marketing Council which manages corn check off funds. He was featured in the “Farmers Feed Us” campaign which targets urban populations with farm facts.  His corn production message was clearly visible in the Washington DC metro complex.     We are fortunate to frequently host delegations of grain importers and buyers from around the globe.  We believe the effort put forth in these activities will pay dividends in greater understanding of our industry and more demand for our products.</p>
      </section>
    </div>
  </Layout>
)

export const query = graphql`
  query {
    headerImage: file(relativePath: { eq: "industry-leadership-header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default IndustryLeadershipPage
